@use "sass:map";

$font-primary: poppins, sans-serif;

$colors: (
    'green': #3BA549,
    'orange': #E57824,
    'purple': #7F3BA5,
    'blue': #427FDB,
    'pink': #F33E93,
    'red': #D61A2D,
    'yellow':#EFB42A,
    'dark-green': #0E471A,
    'white': #FFFFFF,
    'dark-blue': #0E2547,
);

@each $color, $value in $colors {
    .#{$color}-bg {
        background: $value;
    }
    .#{$color}{
        color: $value;
    }
}

@function get_color($color){
    @return map.get($colors, $color);
}