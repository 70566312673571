@import 'vars';

@font-face {
    font-family: $font-primary;
    font-display: swap; /* Define how the browser behaves during download */
  }
body {
    font-family: $font-primary; 
    font-size: 16px;
    color: #000;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.highlight {
    color:#bbbbbb;
    font-size:40px;
}

.button {
    line-height: 52px;
    text-align: center;
    text-decoration: none;
    width: 182px;
    cursor: pointer;
    border-radius: 26px;
    background-color: transparent;
    border: 1px solid white;
    margin-left: auto;
    margin-top: 2rem;
    color:#fff;
    vertical-align: middle;
    height: 52px;
    font-size: 20px;
    font-family: $font-primary;
    &:hover{
        transform: scale(1.07);
    }
}


img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}

.font-medium{
    font-weight: 500;
}

.title{
    font-size: clamp(18px, 4vw, 40px);
    font-weight: 700;
}
.col{
    display:flex;
    flex-direction: column;
}
.row{
    display:flex;
    flex-direction: row;
}
.two-col{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    list-style: none;
    margin:0;
    padding:0;
    @media screen and (max-width:576px) {
        grid-template-columns: 1fr;
    }
}
.three-col{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    @media screen and (max-width:998px) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width:576px) {
        grid-template-columns: 1fr;
    }
}
hr{
    border-color: get-color('dark-blue')!important;
}
h2{
    font-size: clamp(16px, 4vw, 30px);
    font-weight: 400;
}
.cke_editable{
    background-color: #cfcfcf;
}